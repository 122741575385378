import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section
      className=" is-flex"
      style={{ height: "75vh", justifyContent: "center" }}
    >
      <div className="colum has-text-centered is-flex nf-content ">
        <h1>
          Greška <span style={{ color: "#0772ba" }}>404</span>.
        </h1>
        <h1>Tražena stranica ne postoji.</h1>
        <Link to="/" className="button is-link is-size-5">
          Početna
        </Link>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
